import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';

const useRecipeCategories = () => {
  const store = useStore();

  const { selectedRecipe } = useRecipeItem();

  const categoryShowMsg = ref(false);
  const categoryMsg = ref('');
  const categoryMsgStatus = ref('form-');

  const toggleCategory = async (id) => {
    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/registerCategory',
      id
    );

    categoryShowMsg.value = true;
    categoryMsg.value = msg;
    categoryMsgStatus.value += status ? 'success' : 'error';

    setTimeout(() => {
      categoryShowMsg.value = false;
      categoryMsg.value = '';
      categoryMsgStatus.value = 'form-';
      if (status) selectedRecipe(recipeID);
    }, 2800);
  };
  return {
    categories: computed(
      () => store.getters['cmsRecipes/getRecipeSelectedCategories']
    ),
    categoryShowMsg,
    categoryMsg,
    categoryMsgStatus,
    toggleCategory,
  };
};

export default useRecipeCategories;
