<template>
  <div class="mb-2">
    <Subtitle subtitle="Categorías" />
    <Category v-if="categories.length > 0" />
    <Empty v-else notFound="Categorías" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Category: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Category" */ '@/modules/cms/parts/ingredients/categories/Category.vue'
      )
    ),
  },
  setup() {
    const categories = [];

    return {
      categories,
    };
  },
};
</script>
