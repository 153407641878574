<template>
  <div class="mb-1">
    <Subtitle subtitle="Categorías" />
    <template v-if="categories.metaCategories.length > 0" class="mb-2">
      <template
        v-for="(metacategory, index) in categories.metaCategories"
        :key="index"
      >
        <SubCategory
          v-if="categories.data[metacategory]"
          :metacategory="metacategory"
          :categories="categories.data[metacategory]"
          :toggleCategory="toggleCategory"
        />
      </template>
    </template>
  </div>
  <Message
    v-if="categoryShowMsg"
    :message="categoryMsg"
    :messageType="categoryMsgStatus"
  />

  <Empty v-if="categories.metaCategories.length === 0" notFound="categorías" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeCategories from '@/modules/cms/composables/recipes/useRecipeCategories';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    SubCategory: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SubCategory" */ '@/modules/cms/parts/recipes/categories/SubCategory.vue'
      )
    ),
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },

  setup() {
    return {
      ...useRecipeCategories(),
    };
  },
};
</script>
