<template>
  <div class="mb-2">
    <Subtitle subtitle="Características" />

    <FormAddCategory />
    <Separator v-if="categories.length > 0" />
    <FormEditCategory v-if="categories.length > 0" />

    <Empty v-else notFound="Características" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Separator: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Separator" */ '@/modules/cms/components/Separator.vue'
      )
    ),
    FormAddCategory: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormAddCategory" */ '@/modules/cms/parts/ingredients/characteristics/FormAddCategory.vue'
      )
    ),
    FormEditCategory: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormEditCategory" */ '@/modules/cms/parts/ingredients/characteristics/FormEditCategory.vue'
      )
    ),
  },
  setup() {
    const categories = [];
    return {
      categories,
    };
  },
};
</script>
